import styled from 'styled-components'

const TABLET_SIZE_MIN = '768px'

export const AlertContainer = styled.div`
    position: fixed;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgb(255, 238, 147);
    border: 2px solid rgb(254, 211, 80);
    box-shadow: 0px 0px 24px rgba(31, 33, 40, 0.12);
    border-radius: 4px;

    @media (max-width: ${TABLET_SIZE_MIN}) {
        position: fixed;
        top: 7.5rem !important;
        left: unset;
        transform: unset;
        margin-left: 4%;
        margin-right: 4%;
        width: auto;
    }
`
