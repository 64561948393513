import { FC, useState } from 'react'
import { Alert } from '@mui/material'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import { tokens } from '@JOTAJornalismo/jota-design-system'
import Link from 'next/link'
import { AlertContainer } from '../style/alert-jota.style'

const { fonts } = tokens

type AlertJotaProps = {
    isLogged: boolean
}

const AlertJota: FC<AlertJotaProps> = ({ isLogged }) => {
    const [open, setOpen] = useState(true)

    return (
        <>
            {open && (
                <AlertContainer>
                    <Alert
                        onClose={() => setOpen(false)}
                        severity='warning'
                        icon={
                            <ErrorOutlineIcon
                                fontSize='inherit'
                                style={{ color: 'rgb(254 , 211, 80)' }}
                            />
                        }
                        sx={{
                            fontSize: '0.89rem !important',
                            color: '#3C3F4C',
                            fontFamily: fonts.jotaBody
                        }}
                    >
                        Você foi redirecionado para a nova plataforma do JOTA.{' '}
                        {!isLogged && (
                            <>
                                <Link
                                    href='/login'
                                    style={{
                                        fontWeight: 'bold',
                                        textDecoration: 'underline'
                                    }}
                                >
                                    Faça login
                                </Link>{' '}
                                para acessar os conteúdos PRO.
                            </>
                        )}
                    </Alert>
                </AlertContainer>
            )}
        </>
    )
}

export default AlertJota
